* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

img {
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
  border-radius: 8px;
  overflow: hidden;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-left: 2px solid #f5f6f7;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #babac0;
}

::-webkit-scrollbar-button {
  display: none;
}

.MuiAutocomplete-popper {
  z-index: 999999999999 !important;
}

.MuiPaper-root,
.MuiPopover-paper {
  background-image: none !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.MuiPopover-root {
  z-index: 5000 !important;
  max-width: 350px;
  border-radius: 16px;
  max-height: 400px;
}